import Head from 'next/head';

import { NextSeo } from 'next-seo';
import PropTypes from 'prop-types';

import AssembleLayout from '@/src/layouts/AssembleLayout';
import MainLayout from '@/src/layouts/MainLayout';
// import { newsroomArticlesGet } from '@/src/lib/prismic/queries';
import IndexView from '@/src/views/root/IndexView';

function HomePage({ doc = undefined, preview = undefined }) {
  return (
    <AssembleLayout Layout={MainLayout} Component={IndexView} doc={doc} preview={preview}>
      <NextSeo
        title="Tudo para educação. Educação para todos"
        description="Conheça a maior plataforma de e-commerce e marketplace para o setor educacional."
        openGraph={{
          title: 'Tudo para educação. Educação para todos',
        }}
      />
      <Head>
        <link rel="preload" href="/images/illustrations/home/hero.svg" as="image" />
      </Head>
    </AssembleLayout>
  );
}

HomePage.propTypes = {
  doc: PropTypes.object,
  preview: PropTypes.bool,
};

// export async function getStaticProps(context) {
//   const { preview = false, previewData = {} } = context;
//   const { ref } = previewData;
//   const options = { page: 1, pageSize: 3 };

//   const articlesGraphQuery = `{
//     newsroom-article {
//       cover
//       title
//       subtitle
//       date
//       link
//       author {
//         name
//         avatar
//       }
//     }
//   }`;

//   const articles = await newsroomArticlesGet(
//     { ...options, graphQuery: articlesGraphQuery },
//     { ref },
//   );

//   return {
//     props: { doc: { articles }, preview },
//     revalidate: 180,
//   };
// }

export default HomePage;
